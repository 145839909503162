import React, { useEffect, useState } from 'react'
import '../styles/formPage.css'
import { url } from '../URL'
import axios from 'axios'
import { BsTrash } from 'react-icons/bs'
import formatDate from './formatDate'
import getTime from './getTime'
import html2canvas from 'html2canvas'
import RecieptAlert from './RecieptAlert'
function getCurrentTime12HourFormat() {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    
    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';
    
    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    
    // Format minutes as two digits
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Return the formatted time
    return `${hours}:${formattedMinutes} ${period}`;
}
function AddSession() {
    const [data, setData] = useState({
        patient:"",
        service:"",
        category:"",
        publisher:"",
        cost:0,
        start:'',
        startHour:"",
        responsible:"",
        responsibleID:"",
        end:"",
        number:1,
        discount:0,
        paid:0,
        remaining:0,
        notes:""
    })
   
    const [suggestion, setSuggestion] = useState("")
    const [patientID, setPatientID] = useState('')
    const [responsibleID, setResponsibleID] = useState('')
    // const [publisherID, setPublisherID] = useState('')
    const [msg, setMsg] = useState('')
    const [dropdowns, setDropdowns] = useState({
        service:false,
        category:false,
        wavelength:false,
        responsible:false
    })
    const [categories, setCategories] = useState([])
    const [patients, setPatients] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [services, setServices] = useState([])
    function openDropdown(dropdown){
        setDropdowns({
            service: dropdown === 'service' ? true : false,
            category: dropdown === 'category' ? true : false,
            wavelength: dropdown === 'wavelength' ? true : false,
            responsible: dropdown === 'responsible' ? true : false,
        })
    }
    const [isLoading, setIsLoading] = useState(true)
    const [sessionServices,setSessionServices] = useState([])
    const [laserData, setLaserData] = useState({
        wavelength:"",
        lensNumber:0,
        machineName:'',
        pulseDuration:0,
        count:0,
        fluenceNumber:0,
        notes:""
    })
    const [selectedService, setSelectedService] = useState(0)
    useEffect(() => {
        if(sessionServices.length === 1){
            setSelectedService(sessionServices[0])
        }
        console.log(sessionServices)
    }, [sessionServices])
 
    const [employees, setEmployees] = useState([])
    async function loadData(){
        await axios.get(`${url}/sessions/patients/`, {withCredentials:true})
                        .then(res => {
                            setPatients(res.data.data)
                        })
                        .catch(err => {
                            console.log(err.response.data.message)
                        })
        await axios.get(`${url}/sessions/category/`, {withCredentials:true})
                    .then(res => {
                        setCategories(res.data.data)
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                    })
        await axios.get(`${url}/sessions/employees/`, {withCredentials:true})
                    .then(res => {
                        setIsLoading(false)
                        setEmployees(res.data.data)
                    })
                    .catch(err => {
                        setIsLoading(false)
                        console.log(err.response.data.message)
                    })
    }
    useEffect(() => {
        if(isLoading){
            loadData()
        }
    }, [isLoading])
    useEffect(() => {
        if(data.patient){
            var p = patients.find(d => {return d.name.includes(data.patient)})
            if(p){
                setSuggestion(p)
            }else {
                setSuggestion('')
            }
        }
        window.addEventListener('keyup', (e) => {
            if(e.key === 'ArrowLeft' && suggestion){
                setData((d) => {return {...d, patient:suggestion.name, phone:suggestion.phone}})
                setPatientID(suggestion._id)
            }
        })
    }, [data.patient,suggestion, patients])
    function convertDataToString(data) {
        return data
            .map(item => `${item.category} ${item.name}: ${item.sessions} جلسات`)
            .join(', ');
    }
    const [isCreatingReceipt, setIsCreatingReceipt] = useState(false)
    const [afterNotesActivity, setAfterNotesActivity] = useState(false)
    const [receiptUrl, setReceiptUrl] = useState("")
    const [isReceiptActive, setIsReceiptActive] = useState(false)
    const generateRec = async (sessionData) => {
        const element = document.getElementById("receiptsPaper"); // Replace with your div's ID
        
        const relation = sessionData.map(d => {return d._id}).join("_")
        const canvas = await html2canvas(element);
        const dataUrl = canvas.toDataURL("image/png"); 
        const d = {...data, service:receiptProcessedData}
        const totalPaid = sessionData.reduce((sum, item) => sum + item.paid, 0)
        const requestData = {...d, image:dataUrl, sessionID:sessionData[0]._id, relation,paid:totalPaid}
        // const date = formatDate(Date.now())
        setIsCreatingReceipt(true)
        const startHour = getCurrentTime12HourFormat()
        try {
          await axios.post(`${url}/sessions/generate-receipt`, {data:{...requestData, responsibleID, start:Date.now(),startHour}}, {
                withCredentials:true,
                // headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                if(res.status === 201){
                  setIsCreatingReceipt(false)
                  alert("تم حفظ الوصل بنجاح")
                }
              })
              .catch(err => {
                console.log(err)
              })
            ;
        } catch (error) {
            console.error('Error generating receipt:', error);
        }
      };
    
    
    async function createSession() {
        if(Boolean( sessionServices.some(d => d.paid !== 0) && data.patient !== '')){
            try {
                const serviceRequests = sessionServices.map((service) => {
                    const remaining = getRemaining(service.price, service.discount, service.paid, service.sessions);
                    const serviceData = {
                        ...laserData,
                        patient: data.patient,
                        patientID,
                        category: service.category,
                        service: service.name,
                        number: service.sessions,
                        responsibleID,
                        responsible: data.responsible,
                        cost: service.price,
                        discount: service.discount,
                        paid: service.paid,
                        remaining,
                        notes: service.notes,
                        end: "",
                        start: service.start,
                        startHour: service.startHour,
                    };
        
                    // Send a POST request for this service
                    return axios.post(`${url}/sessions/create`, { data: serviceData }, { withCredentials: true });
                });
        
                // Wait for all requests to complete
                const responses = await Promise.all(serviceRequests);
        
                // Process responses
                responses.forEach((res) => {
                    console.log(res.data.data); // Log response data
                });
    
                setIsReceiptActive(true)
                generateRec(sessionServices)
                setMsg("تم أضافة الجلسات بنجاح");
            } catch (err) {
		alert('حدث خطأ')
		console.log(err)
                // Handle errors
                if (err.response) {
                    //console.error(err.response.data.message);
                    //setMsg(err.response.data.message);
        
                    if (err.response.status === 400) {
                        //window.location = '/login';
                    }
                } else {
                    //console.error(err.message);
                    setMsg("An unexpected error occurred.");
                }
            }
        }else {
            setMsg("أرجوك قم بملء جميع الحقول")
        }
        
    }
    
    async function updateServices(category){
        await axios.get(`${url}/sessions/services/get-services?category=${category}`, {withCredentials:true})
                    .then(res => {
                        setServices(res.data.data)
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                    })
    }


    function getDiscount(price, discount, number){
        return (price * number) - discount
    }

    function getRemaining(price, discount, paid, number){
        const totalPrice = price * number 
        const priceAfterDiscount = totalPrice - discount
        const remaining = priceAfterDiscount - paid 
        return remaining
    }
  

    // useEffect(() => {
    //     getDiscount(data.cost, data.discount)
    // }, [data.cost, data.discount])
    const [receiptProcessedData,setReceiptProcessedData] = useState('')
    useEffect(() => {
        setReceiptProcessedData(convertDataToString(sessionServices))
        // console.log()
    }, [sessionServices])
    useEffect(() => {
        updateServices(categoryId)
        if(!data.category.includes('ليزر')){
            setLaserData({
                wavelength:"",
                lensNumber:0,
                machineName:'',
                pulseDuration:0,
                count:0,
                fluenceNumber:0
            })
        }
    }, [categoryId, data.category])

    function updateServiceField(serviceId, key, value) {
        setSessionServices((prevServices) =>
            prevServices.map((service) =>
                service._id === serviceId
                    ? { ...service, [key]: value }
                    : service
            )
        );
    }
    function addNewService(service){
        if(!sessionServices.find(d => d._id === service._id)){
            setData((d => {return {...d, service: service.name, cost:service.price}}))
            setSessionServices(prev => {
                return [...prev, {...service, category:data.category, sessions:1, discount:0, paid:0, start:"", startHour:"", notes:""}]
            })
        }
        openDropdown('')
    }

    function removeService(service){
        const id = service._id 
        setSessionServices((prevItems) => prevItems.filter((item) => item._id !== id))
        setSelectedService(sessionServices[0])
    }
    return (
    <div className='form-page add-session'>
        <RecieptAlert hasAfterNotes={true} addNotes={() => {
            setAfterNotesActivity(true)
        }} url={receiptUrl} data={{...data,
         service:receiptProcessedData,
         paid:sessionServices.reduce((sum, item) => sum + item.paid, 0) || 0
         }} active={isReceiptActive} />
        <div className={isReceiptActive ? 'shadow active' : "shadow"} onClick={() => {setIsReceiptActive(false)}}></div>
        <div className='form'>
            <form>
                <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                    <div className='field-container'>
                        <input className={data.patient === '' ? 'field' : 'field active'} type='text' name='name' value={data.patient} onChange={(e) => {setData(d => {return {...d, patient:e.target.value}})}}  />
                        <label className={data.patient === '' ? '' : 'active'} htmlFor='name'>الأسم</label>
                        <p className={suggestion && data.patient !== suggestion && data.patient !== '' ? 'suggessions active' : 'suggessions'}>{suggestion.name}</p>
                    </div>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>المسؤول عن الجلسة</label>
                        <div className='category'  onClick={() => {
                        openDropdown('responsible')
                        }}
                        >{data.responsible || "غير محدد"}</div>
                        <div  className={dropdowns.responsible ? 'dropdown active' : 'dropdown'}>
                        <ul>
                            {
                                employees ? employees.map((employee, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            setData((d) => {return {...d, responsible:employee.name}})
                                            // setCategoryId(category._id)
                                            setResponsibleID(employee._id)
                                            setData(d => {return {...d, responsible:employee.name}})
                                            openDropdown('')
                                        }}>{employee.name}</li>
                                    )
                                }) : "خطأ"
                            }
                        </ul>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='field-container'>
                        <input className={data.phone === '' ? 'field' : 'field active'} type='text' name='name' value={data.phone} onChange={(e) => {setData(d => {return {...d, phone:e.target.value}})}}  />
                        <label className={data.phone === '' ? '' : 'active'} htmlFor='name'>رقم الهاتف</label>
                    </div>
                </div>
                <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>الفئة</label>
                        <div className='category'  onClick={() => {
                        openDropdown('category')
                        }}
                        >{data.category || "غير محدد"}</div>
                        <div  className={dropdowns.category ? 'dropdown active' : 'dropdown'}>
                        <ul>
                            {
                                categories.map((category, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            setData((d) => {return {...d, category:category.name, service:''}})
                                            setCategoryId(category._id)
                                            openDropdown('')
                                        }}>{category.name}</li>
                                    )
                                }) 
                            }
                        </ul>
                        </div>
                    </div>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>الخدمة</label>
                        <div className='service'  onClick={() => {
                        openDropdown('service')
                        }}
                    >{data.service || "غير محدد"}</div>
                        <div  className={dropdowns.service ? 'dropdown active' : "dropdown"}>
                        <ul>
                            {
                                services ? services.map((service, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            addNewService(service)
                                        }}>{service.name}</li>
                                    )
                                }) : ''
                            }
                        </ul>
                        </div>
                    </div>
                </div>
                {
                    sessionServices.length > 0 ? (
                        <>
                        <h2 style={{marginTop:'1rem'}}>الخدمة المحددة: {selectedService?.category + ' ' + selectedService?.name}</h2>
                         <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                            <div className='field-container'>
                                    <input className={selectedService?.start === '' ? 'field' : 'field active'} type='date' name='start' value={sessionServices.find(d => d._id === selectedService._id)?.start} onChange={(e) => updateServiceField(selectedService._id, 'start', e.target.value)}  />
                                    <label className={selectedService?.start === '' ? '' : 'active'} htmlFor='name'>تاريخ الجلسة</label>
                                </div>
                                <div className='field-container'>
                                    <input className={selectedService?.startHour === '' ? 'field' : 'field active'} type='time' name='startHour' value={sessionServices.find(d => d._id === selectedService._id)?.startHour} onChange={(e) => updateServiceField(selectedService._id, 'startHour', e.target.value)} />
                                    <label className={selectedService?.startHour === '' ? '' : 'active'} htmlFor='name'>وقت الجلسة</label>
                                </div>
                            </div>
                        <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(6, 1fr)", gap:".5rem"}}>
                        <div className='field-container'>
                            <input className={data.number === '' ? 'field' : 'field active'} type='number' min={1} step={1} name='number' value={sessionServices.find(d => d._id === selectedService._id)?.sessions} onChange={(e) => updateServiceField(selectedService._id, 'sessions', parseFloat(e.target.value))} />
                            <label className={data.number === '' ? '' : 'active'} htmlFor='number'>عدد الجلسات</label>
                        </div>
                        <div className='field-container'>
                            <input className={data.cost === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={sessionServices.find(d => d._id === selectedService._id)?.price} onChange={(e) => updateServiceField(selectedService._id, 'price', parseFloat(e.target.value))} />
                            <label className={data.cost === '' ? '' : 'active'} htmlFor='cost'>سعر الجلسة</label>
                        </div>
                        <div className='field-container'>
                            <input className={data.discount === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={sessionServices.find(d => d._id === selectedService._id)?.discount} onChange={(e) => updateServiceField(selectedService._id, 'discount', parseFloat(e.target.value))}  />
                            <label className={data.discount === '' ? '' : 'active'} htmlFor='cost'>الخصم</label>
                        </div>
                        <div className='field-container disabled'>
                            <input className='field active' disabled type='number' min={0} step={1000} name='cost' value={getDiscount(
                                sessionServices.find(d => d._id === selectedService._id)?.price,
                                sessionServices.find(d => d._id === selectedService._id)?.discount, 
                                sessionServices.find(d => d._id === selectedService._id)?.sessions) || "nothing"} />
                            <label className='active' htmlFor='cost'>المبلغ بعد الخصم</label>
                        </div>
                        <div className='field-container'>
                            <input className={data.paid === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={sessionServices.find(d => d._id === selectedService._id)?.paid} onChange={(e) => updateServiceField(selectedService._id, 'paid', parseFloat(e.target.value))}  />
                            <label className={data.paid === '' ? '' : 'active'} htmlFor='cost'>المدفوع</label>
                        </div>
                        <div className='field-container disabled'>
                            <input className='field active' disabled type='number' min={0} step={1000} name='remaining' value={getRemaining(selectedService?.price, selectedService?.discount, selectedService?.paid, selectedService?.sessions) || "nothing"} onChange={(e) => {setData(d => {return {...d, cost:e.target.value}})}}  />
                            <label className='active' htmlFor='remaining'>المتبقي</label>
                        </div>
                    </div>
                     <div className='row'>
                    <div className='field-container'>
                        <input className='field active' type='text' name='notes' value={sessionServices.find(d => d._id === selectedService._id)?.notes} onChange={(e) => updateServiceField(selectedService._id, 'notes', e.target.value)}  />
                        <label className='active' htmlFor='notes'>ملاحظات</label>
                    </div>
                </div>
                <div className='services-info'>
                    <table>
                        <thead>
                            <tr>
                                <th>أسم الفئة</th>
                                <th>أسم الخدمة</th>
                                <th>التاريخ</th>
                                <th>الوقت</th>
                                <th>عدد الجلسات</th>
                                <th>السعر</th>
                                <th>الخصم</th>
                                <th>المجموع</th>
                                <th>المدفوع</th>
                                <th>حذف</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            sessionServices.map((service, index) => {
                                return (
                                    <tr key={index} onClick={() => {setSelectedService(service)}} className={selectedService._id === service._id ? "selected" : ""}>
                                    <td>
                                        {service.category}
                                    </td>
                                    <td>
                                        {service.name}
                                    </td>
                                    <td>
                                        {service.start ? formatDate(service.start) : "غير محدد"}
                                    </td>
                                    <td>
                                        {service.startHour ? getTime(service.startHour) : "غير محدد"}
                                    </td>
                                    <td>
                                        {service.sessions}
                                    </td>
                                    <td>
                                        {service.price}
                                    </td>
                                    <td>
                                        {service.discount}
                                    </td>
                                    <td>
                                        {service.sessions * service.price - service.discount}
                                    </td>
                                    <td>
                                        {service.paid}
                                    </td>
                                    <td>
                                        <div onClick={() => {removeService(service)}} className='delete-btn-icon'>
                                            <BsTrash />
                                        </div>
                                    </td>
                                </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className='row'>
                    <button onClick={(e) => {
                        e.preventDefault()
                        createSession()
                        
                    }}>اضافة</button>
                </div>
                </>
                    ) : ""
                }
            </form>
            <p style={{fontSize:"var(--paragraph)", color:"var(--primary)", textAlign:"center", marginTop:"1rem"}}>{msg}</p>
        </div>
    </div>
  )
}

export default AddSession
